import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { I18nContext } from '../../I18n';

interface IFooterLink {
  label: string;
  i18n: string;
  url: string;
}
function Footer({ configState }: any) {
  const i18n = useContext(I18nContext);
  const footerLinks = configState.footer_links ? JSON.parse(configState.footer_links) : [];

  return (
    <div className="px-footer-inner-container">
      <div className="px-footer-copyright px-text-description">
        {i18n.business_copyrights_text || configState.business_copyrights_text}
      </div>
      <div className="px-footer-link">
        {Array.isArray(footerLinks) &&
          footerLinks.map((val: IFooterLink) => (
            <a href={val.url} target="_blank" rel="noreferrer" key={val.label}>
              {i18n[val.i18n] || val.label}
            </a>
          ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    configState: state.configState,
  };
};
export default connect(mapStateToProps)(Footer);
