import { publicPrivateRoute } from '../../_lib/constant';
import { fieldValidation } from '../../_lib/lib';
import { deepCopy, isEmpty } from '../../_lib/util';
import {
  IAdditionalParties,
  IAddressFields,
  IEUSquestion,
  IField,
} from '../../types/IAccountSetup';
import { INextAction } from '../../types/IAccountSetup';
import React from 'react';
import { IMessageType } from '../../types/IMessageType';

const checkIfFieldEmpty = (input: any) => {
  if (typeof input.value === 'boolean') {
    return !input.value;
  }
  return !input.value?.trim() || input.value === 'px-disabled-menu-select';
};
export const sanitizeSelectOptions = (options: any) => {
  return options && options.map((option: any) => ({ id: option.id, option: option.label }));
};

export const sanitizedSteps = (steps: INextAction[]) => {
  if (isEmpty(steps)) return;
  // return steps.map((step) => {
  if (steps[0].fields instanceof Array)
    return steps[0].fields?.reduce((acc: any, field: IField) => {
      acc[field.id] = field.value;
      return acc;
    }, {});
  return {};
  // });
};

export const accountSetupFieldValidation = (inputField: any, type: any, I18n?: any) => {
  if (['display'].includes(inputField.fieldType)) return;
  if (inputField.required && checkIfFieldEmpty(inputField)) return 'Required';
  if (type && inputField.value) return fieldValidation(inputField.value?.trim(), type, I18n);
  return;
};

export const sanitizeFields = (fields: IField[]) => {
  return fields.reduce((acc, field) => {
    acc[field.id] = field.value ?? '';
    return acc;
  }, {} as any);
};

export const checkTypeElements = ['checkbox'];

export const appendBrIfExists = (text: any, label?: string) =>
  text ? (
    <>
      {label && <span>{`${label}: `}</span>}
      <span>{text}</span>
      <br />
    </>
  ) : (
    <></>
  );

export const addressClosedList = (step: Record<string, string>) => {
  const fields: Record<string, string> = {};
  if (step['CONTACT_NAME']) fields.CONTACT_NAME = 'CONTACT_NAME';

  if (step['OWNER_NAME']) fields.OWNER_NAME = 'OWNER_NAME';
  else if (step['BUSINESS_OWNER_NAME']) fields.OWNER_NAME = 'BUSINESS_OWNER_NAME';

  if (step['ADDRESS_LINE_1']) fields.ADDRESS_LINE_1 = 'ADDRESS_LINE_1';
  else if (step['BUSINESS_OWNER_ADDRESS_LINE_1'])
    fields.ADDRESS_LINE_1 = 'BUSINESS_OWNER_ADDRESS_LINE_1';

  if (step['ADDRESS_LINE_2']) fields.ADDRESS_LINE_2 = 'ADDRESS_LINE_2';
  else if (step['BUSINESS_OWNER_ADDRESS_LINE_2'])
    fields.ADDRESS_LINE_2 = 'BUSINESS_OWNER_ADDRESS_LINE_2';

  if (step['CITY']) fields.CITY = 'CITY';
  else if (step['BUSINESS_OWNER_CITY']) fields.CITY = 'BUSINESS_OWNER_CITY';

  if (step['STATE']) fields.STATE = 'STATE';
  else if (step['BUSINESS_OWNER_STATE']) fields.STATE = 'BUSINESS_OWNER_STATE';

  if (step['ZIP_CODE']) fields.ZIP_CODE = 'ZIP_CODE';
  else if (step['BUSINESS_OWNER_ZIP_CODE']) fields.ZIP_CODE = 'BUSINESS_OWNER_ZIP_CODE';

  if (step['COUNTRY']) fields.COUNTRY = 'COUNTRY';
  else if (step['BUSINESS_OWNER_COUNTRY']) fields.COUNTRY = 'BUSINESS_OWNER_COUNTRY';

  return (
    <p>
      {fields.CONTACT_NAME && appendBrIfExists(step[fields.CONTACT_NAME])}
      {fields.OWNER_NAME && appendBrIfExists(step[fields.OWNER_NAME])}
      {fields.ADDRESS_LINE_1 && appendBrIfExists(step[fields.ADDRESS_LINE_1])}
      {fields.ADDRESS_LINE_2 && appendBrIfExists(step[fields.ADDRESS_LINE_2])}
      <span>
        {['CITY', 'STATE', 'ZIP_CODE']
          .map((key) => step[fields[key]])
          .filter((value) => !!value)
          .join(', ')}
      </span>
      <br />
      {fields.COUNTRY && appendBrIfExists(step[fields.COUNTRY])}
    </p>
  );
};

export const isDependantFieldValueSelected = ({
  dependantField,
  dependantFieldVal,
  conditions,
}: any) =>
  dependantField &&
  conditions &&
  conditions
    .find((condition: any) => condition.field === dependantField)
    ?.values?.includes(dependantFieldVal);

export const infoContent = () => (
  <div>
    <p>
      You've told us that your orders for the U.S. shipping address above will be sent on to an
      address in another country.
    </p>
    <p>
      Because of U.S. export rules, we need more info from you about your international shipping.
      Here's what we need:
    </p>
    <ol>
      <li>
        <b>Shipping Company Details</b>
        <span>
          : Please let us know the name of the company shipping your orders from the U.S. to the
          other country.
        </span>
      </li>
      <li>
        <b>International Destination Address:</b>
        <span>
          We need to know where your orders are going. Please provide the full address in the other
          country.
        </span>
      </li>
      <li>
        <b>Proof of Export:</b>
        <span>
          For each order sent to this U.S. address, we'll need to collect a "proof of export". This
          can be an airway bill or a bill of lading. Don't worry if you're not sure what these are -
          your shipping company can give you one for each shipment.
        </span>
      </li>
    </ol>
    <span>Thanks for helping us keep everything in order!</span>
  </div>
);

export const infoContentV1 = () => (
  <div>
    <p>
      You've told us that your orders for the U.S. shipping address above will be sent on to an
      address in another country.
    </p>
    <p>
      Because of U.S. export rules, we need more info from you about your international shipping.
      Here's what we need:
    </p>
    <ol>
      <li>
        <b>Shipping Company Details</b>
        <span>
          : Please let us know the name of the company shipping your orders from the U.S. to the
          other country.
        </span>
      </li>
      <li>
        <b>International Destination Address:</b>
        <span>
          We need to know where your orders are going. Please provide the full address in the other
          country.
        </span>
      </li>
    </ol>
    <span>Thanks for helping us keep everything in order!</span>
  </div>
);

const purchaseOptions = [
  {
    id: 'wholesale',
    option: 'Wholesale',
  },
  {
    id: 'retail',
    option: 'Retail',
  },
  { id: 'other', option: 'Others' },
];

export const qstnOptions = [
  {
    id: 'yes',
    option: 'Yes',
  },
  {
    id: 'no',
    option: 'No',
  },
];

export const initialUserInfoState = {
  firstName: '',
  lastName: '',
  title: '',
  signature: '',
};

const addressField = {
  NAME: '',
  COUNTRY: '',
  ADDRESS_LINE_1: '',
  ADDRESS_LINE_2: '',
  CITY: '',
  STATE: '',
  ZIP_CODE: '',
};

export const additionalPartyInfo = { id: 1, key: 1, ...deepCopy(addressField) };

export const initialFormState = {
  company: deepCopy({
    companyName: addressField,
    ownerName: addressField,
  }),
  individual: {
    companyName: deepCopy(addressField),
  },
};

const addressOptions = [
  { id: 'company', option: 'Company' },
  { id: 'individual', option: 'Indivdual' },
];

export const additionalParties = {
  id: 'additional_parties',
  type: 'radio',
  isAddressButtonField: true,
  label:
    'Have you already provided the names and addresses of all companies and individuals party to the transaction, including Owners who will resell the goods once exported?',
  infoLabel: 'Explanation',
  options: qstnOptions,
  required: true,
  maxLength: 200,
  optionRef: null,
  additionInfoOption: 'yes',
} as const;

export const EUSaddressRelatedFields: IEUSquestion[] = [
  {
    id: 'company_or_individual_address',
    type: 'radio',
    isAddressField: true,
    label: (
      <>
        <b>
          For businesses registered in the US and exporting goods to another country for resale:{' '}
        </b>
        Please provide the names and addresses of all additional individuals or companies party to
        these transactions, including the owners. Include all parties who will receive or resell
        these exported goods other than those you've already provided.
      </>
    ),
    infoLabel: 'Explanation',
    options: addressOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'yes',
  },
  additionalParties,
];

export const EUSaddressRelatedFieldsv2: IEUSquestion[] = [
  {
    id: 'company_or_individual_address',
    type: 'radio',
    isAddressField: true,
    label: (
      <>
        Please enter the names and addresses of all <b>international</b> individuals or companies
        (and their owners) who will receive and/ or resell the exported goods.
      </>
    ),
    infoLabel: 'Explanation',
    options: addressOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'yes',
  },
  additionalParties,
];

export const eusQuestionArrayList: IEUSquestion[] = [
  {
    id: 'purpose_of_purchase',
    type: 'radio',
    label: 'State the purpose of this purchase and describe the end-use application',
    infoLabel: 'Purpose of purchase',
    options: purchaseOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'other',
  },
  {
    id: 'laws_and_location',
    type: 'radio',
    label:
      'Is the Company organized under the laws of or located in, or does it operate in, Syria, Iran, Cuba, North Korea, Venezuela, Russia, Belarus, or Ukraine?',
    infoLabel: 'Explanation',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'yes',
  },
  {
    id: 'owned_by_govt',
    type: 'radio',
    label:
      "Is the Company owned or controlled, directly or indirectly, by (a) a government, entity, or resident of any of the countries/territories listed in Question 2, or (b) a person or persons who are listed on any U.S. sanctions or export controls restricted or prohibited party lists, including the U.S. Treasury Department's List of Specially Designated Nationals and Blocked Persons (SDN List) or Sectoral Sanctions Identification List (SSI List) or the U.S. Commerce Department’s Entity, Denied Persons, Unverified, or MEU (Military End User) Lists?",
    infoLabel: 'List Countries',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'yes',
  },
  {
    id: 'will_items_be_exported',
    type: 'radio',
    label:
      'Will the Items be directly or indirectly exported, re-exported, transferred, or released to: (1) Cuba, North Korea, Iran, Syria, Ukraine, Venezuela, Belarus, Russia, or Burma?',
    infoLabel: 'Explanation',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'yes',
  },
  {
    id: 'destination_countries',
    type: 'text',
    label:
      'Please list all countries of ultimate destination (note: please provide country names, not the names of regions e.g. Europe and South America):',
    infoLabel: 'Explanation',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: '',
  },
  {
    id: 'registered_in_US',
    type: 'radio',
    label: 'Is your business registered in the US?',
    infoLabel: 'Please List Countries',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: '',
    additionalFieldOption: 'yes',
    isAddressField: true,
  },
  {
    id: 'other_countries_export',
    type: 'radio',
    label: 'Will the goods be exported/re-exported to any other countries?',
    infoLabel: 'Please List Countries',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'yes',
    additionalInfoField: {
      id: 'other_countries_export_additional_question',
      type: 'radio',
      label: 'Will the items be in the same condition as when they were purchased?',
      infoLabel: 'Please Explain',
      options: qstnOptions,
      required: true,
      maxLength: 200,
      optionRef: null,
      additionInfoOption: 'no',
    },
  },
  {
    id: 'comply_export_laws',
    type: 'radio',
    label:
      'Do you certify that you will comply with all applicable U.S. Export Control laws and regulations and assure that all items controlled by any Government’s export regulations will be handled by your company in compliance with those regulations? ',
    infoLabel: 'Explanation',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'no',
  },
  {
    id: 'will_not_export_restrict_items',
    type: 'radio',
    label:
      'Do you certify that you will not directly or indirectly export, re-export, transmit, transfer, re-transfer, or cause to be exported, re-exported, transferred, re-transferred, or transmitted, any Restricted Item to any country, individual, corporation, organization, or entity to which such export, re-export, or transmission is restricted or prohibited? ',
    infoLabel: 'Explanation',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'no',
  },
  {
    id: 'certify_distribute_for_civilian_or_commercial',
    type: 'radio',
    label:
      'Do you certify that you will only distribute or sell the export-controlled items listed above for civilian or commercial use? The items that we purchase will not be directly or indirectly exported, reexported, transferred, retransferred, sold for any military end use or to any military end user. Military end user means the national armed services (army, navy, marine, air force, or coast guard), as well as the national guard and national police, government intelligence or reconnaissance organizations, or any person or entity whose actions or functions are intended to support military end uses.',
    infoLabel: 'Explanation',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'no',
  },
  {
    id: 'certify_not_distribute_for_destruction',
    type: 'radio',
    label:
      'Do you certify that you will not use, distribute, or sell any WE SELL CELLULAR items for any nuclear, biological, or chemical weapons, or the missile systems that deliver them, for the design, development, production, testing, stockpiling, or use of any weapons of mass destruction; or for any other use that the U.S. government prohibits?',
    infoLabel: 'Explanation',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'no',
  },
  {
    id: 'consent_and_notify',
    type: 'checkbox',
    isLabelHtml: true,
    label:
      'We certify that we will immediately notify WE SELL CELLULAR if our company or any of the end-users who we distributed or sold to, is or becomes, listed in any Restricted Parties List, or if our export privileges are otherwise denied, suspended, or revoked, in whole or in part, by any U.S. Government entity or agency. https://home.treasury.gov/policy-issues/financial-sanctions/sanctions-programs-and-country-information',
    infoLabel: 'SELECT',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: '',
  },
  {
    id: 'acknowledge_for_license',
    type: 'checkbox',
    isLabelHtml: true,
    label:
      'We acknowledge that it is our responsibility to obtain any license for export/re-export of export-controlled Items, or to transmit information regarding Restricted Items, as may be required by U.S. export regulations and Laws.',
    infoLabel: 'SELECT',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: '',
  },
  {
    id: 'consent_for_damages',
    type: 'checkbox',
    isLabelHtml: true,
    label:
      'We certify that we will indemnify and hold WE SELL CELLULAR harmless for all damages, costs, fines, penalties, attorney fees, and all other expenses arising from a U.S. Government claim or demand that we failed to comply with stated statutes and regulations.',
    infoLabel: 'SELECT',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: '',
  },
  {
    id: 'consent_for_inaccurate_information',
    type: 'checkbox',
    isLabelHtml: true,
    label:
      'We certify that we understand that any inaccurate information or the concealment of any material fact in connection with this communication may result in denial, in whole or in part, of participation in U.S. exports and re-exports.',
    infoLabel: 'SELECT',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: '',
  },
];

const eusv2updatedQuestion: IEUSquestion = {
  id: 'other_countries_export',
  type: 'radio',
  label: 'Are you exporting from the US or re-exporting from your international destination?',
  infoLabel: 'Please List Countries',
  options: qstnOptions,
  required: true,
  maxLength: 200,
  optionRef: null,
  additionInfoOption: 'yes',
  additionalFieldOption: 'yes',
  additionalInfoField: {
    id: 'other_countries_export_additional_question',
    type: 'radio',
    label: 'Will the items be in the same condition as when they were purchased?',
    infoLabel: 'Please Explain',
    options: qstnOptions,
    required: true,
    maxLength: 200,
    optionRef: null,
    additionInfoOption: 'no',
  },
  isAddressField: true,
};

export const getEusQuestionArrayListv2: () => IEUSquestion[] = () => {
  const eusv2 = eusQuestionArrayList
    .filter((opt) => 'registered_in_US' !== opt.id)
    .map((opt: IEUSquestion) => {
      if ('other_countries_export' === opt.id) return eusv2updatedQuestion;
      return opt;
    });
  return eusv2;
};

export const EUSEndDescription = () => (
  <>
    <p>
      This Certification shall survive the term and termination of the relationship between WE SELL
      CELLULAR and the Distributor/Reseller.
    </p>
    <p>
      We acknowledge that the export/re-export/re-transfer of commodities, software, and/or
      technical data (“Restricted Items”) that we have requested, purchased, or intend to purchase
      from WE SELL CELLULAR are subject to the U.S. export control laws and regulations, including,
      but not limited to, the Export Administration Regulations (“EAR”), the International Traffic
      in Arms Regulations (“ITAR”) and the Embargo and Sanction Regulations administered by the U.S.
      Department of the Treasury, Office of Foreign Assets Controls (“OFAC”), among other rules and
      regulations. We agree and undertake to comply with all applicable U.S. export control laws and
      regulations.
    </p>
    <p>
      STATEMENT OF DISTRIBUTOR/RESELLER: We certify by signature that all of the facts contained in
      this statement are true and correct to the best of our knowledge and will apply to the
      disposition of products purchased from WE SELL CELLULAR. We acknowledge the making of any
      false statements or concealment of any material fact in connection with this statement may
      result in denial, in whole or in part, of participation in U.S. exports and re-exports. We
      also certify that if the end user, end use, geographic area for sales, and/or ownership
      information changes, that we will notify WE SELL CELLULAR as there can be additional export
      requirements that may apply. The undersigned certifies that we are responsible officials of
      our organizations and that we are authorized to sign this Export Control Statement on their
      behalf.
    </p>
  </>
);
export const documentStatusChipMap: any = {
  MISSING: {
    label: 'Missing Docs',
    colorClass: 'badge_1  phonex_theme_variant',
  },
  TO_REVIEW: {
    label: 'Docs To Review',
    colorClass: 'badge_1',
  },
  VERIFIED: {
    label: 'Docs Verified',
    colorClass: 'badge_2',
  },
};

export interface IAddressRequiredField {
  id: string;
  validationCode?: string;
}

export const addressRequiredField: IAddressRequiredField[] = [
  {
    id: 'COUNTRY',
    validationCode: 'required',
  },
  {
    id: 'ADDRESS_LINE_1',
  },
  {
    id: 'CITY',
    validationCode: 'required',
  },
];

export const missingRelatedStatus = ['MISSING', 'MISSING_EXPIRED', 'MISSING_REJECTED'];
export const orderOfDocumentstatus = [
  'MISSING',
  'MISSING_EXPIRED',
  'MISSING_REJECTED',
  'TO_REVIEW',
  'VERIFIED',
  'WAIVED_VERIFIED',
  'EXPIRED',
  'REJECTED',
];

export const allowedDocumentExtensions = [
  '.pdf',
  '.gif',
  '.png',
  '.jpg',
  '.jpeg',
  '.tif',
  '.tiff',
  '.docx',
  '.doc',
  '.xlsx',
  '.xls',
  '.txt',
];
export const getDropRejectMessage = (...props: any) => {
  const maxSize = props[2] || 5000000;
  const file = props[0] || null;
  if (!file) return 'Please upload a file';
  if (file.size > maxSize) {
    // 1024 * 1024 = 1048576 bytes
    return `File size cannot exceed ${(maxSize / 1048576).toFixed(
      2
    )} MB. Please select another file.`;
  }
  const extensionString = allowedDocumentExtensions
    .map((extension) => extension.toUpperCase().slice(1))
    .join(', ');
  return 'File type must be one of the following:' + extensionString;
};

interface IAdditionalPartiesValidation {
  additionalPartyInfoList: IAdditionalParties[] | undefined;
  addressFieldValidation: any;
  firstErrorKey: string;
  errorObj: any;
}

export const additionalPartiesValidation = ({
  additionalPartyInfoList,
  addressFieldValidation,
  firstErrorKey,
  errorObj,
}: IAdditionalPartiesValidation) => {
  additionalPartyInfoList?.forEach((additionalParty) => {
    const partyErrorObj: any = {};

    addressFieldValidation.forEach((field: IAddressRequiredField) => {
      const fieldId = field.id as keyof IAddressFields;
      if (!additionalParty[fieldId]?.trim()) partyErrorObj[field.id] = 'Required';
    });

    if (!additionalParty['additionalPartyType']) partyErrorObj['additionalPartyType'] = 'Required';
    else {
      if (
        additionalParty['additionalPartyType'] === 'other' &&
        !additionalParty['additionalPartyTypeInfo']?.trim()
      )
        partyErrorObj['additionalPartyTypeInfo'] = 'Required';
    }

    if (!isEmpty(partyErrorObj)) {
      if (!firstErrorKey) firstErrorKey = `additionalParties-${additionalParty.key}`;
      const additionalPartyId = 'additionalParties-' + additionalParty.key;
      errorObj[additionalPartyId] = partyErrorObj;
    }
  });
  return firstErrorKey;
};

interface ICompanyOrIndividualAddressValidation {
  firstLevelVal: string;
  addressFieldValidation: any;
  addressState: Record<string, any> | undefined;
  errorObj: any;
  firstErrorKey: string;
}

export const companyOrIndividualAddressValidation = ({
  firstLevelVal,
  addressFieldValidation,
  addressState,
  errorObj,
  firstErrorKey,
}: ICompanyOrIndividualAddressValidation) => {
  const companyNameError: any = {},
    ownerNameError: any = {};
  addressFieldValidation.forEach((field: IAddressRequiredField) => {
    const fieldId = field.id as keyof IAddressFields;
    //individual and company -> companyName
    if (!addressState?.[firstLevelVal].companyName[fieldId]?.trim()) {
      companyNameError[fieldId] = 'Required';
      if (!firstErrorKey) firstErrorKey = `${firstLevelVal}-companyName`;
    }
    //only company -> ownerName
    if (firstLevelVal === 'company') {
      if (!addressState?.[firstLevelVal].ownerName[field.id]?.trim()) {
        ownerNameError[field.id] = 'Required';
        if (!firstErrorKey) firstErrorKey = `${firstLevelVal}-ownerName`;
      }
    }
  });

  if (!isEmpty(companyNameError) || !isEmpty(ownerNameError)) {
    errorObj[firstLevelVal] = {};
    if (!isEmpty(companyNameError)) errorObj[firstLevelVal].companyName = companyNameError;
    if (!isEmpty(ownerNameError)) errorObj[firstLevelVal].ownerName = ownerNameError;
  }
  return firstErrorKey;
};

export const missingDocumentNotification = ({
  openSnackBar,
  closeSnackBar,
  history,
  cb,
}: {
  openSnackBar: (message: any, type: IMessageType) => any;
  closeSnackBar: () => void;
  history: any;
  cb: () => void;
}) =>
  openSnackBar(
    <>
      Missing Required Documents{' '}
      <span
        className="mouse-pointer margin-bottom-0"
        onClick={() => {
          history.push(publicPrivateRoute.accountDetails);
          cb();
          closeSnackBar();
        }}
      >
        UPDATE DOCUMENTS
      </span>
    </>,
    'warning'
  );
