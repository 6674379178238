import * as types from '../actions/actionTypes';
import { isAllViaPhonexAndHasExternalSystem } from '../../_lib/rules';

export default function salesOrderReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.SALES_ORDER_SETTINGS_SET:
      const settings = { ...action.settings };
      Object.keys(settings).forEach((participantId) => {
        settings[participantId].isExternalSystemManualSync = isAllViaPhonexAndHasExternalSystem(
          settings[participantId]?.salesOrderUpdateMethod
        );
        settings[participantId].singleButtonOrderFlow = !!settings[participantId]
          .fulfillmentSettings.singleButtonOrderFlow;
        settings[participantId].enableOnholdForManualSyncTenant = !!settings[participantId]
          .fulfillmentSettings.enableOnholdForManualSyncTenant;
      });
      return {
        ...state,
        ...settings,
      };
    default:
      return state;
  }
}
