import React from 'react';
import ReactDOM from 'react-dom';
import PhonexRouter from './PhonexRouter';
import ErrorBoundary from './components/ErrorHandlers/RootLevelErrorBoundary';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import configureStore from './redux/configureStore';
import { InitialState } from './redux/reducers';
import { Provider as ReduxProvider } from 'react-redux';
import I18nWrapper from './I18n';
import { ErrorHandler } from './components/ErrorHandlers/ErrorHandler';

export const store = configureStore(InitialState());

ReactDOM.render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <I18nWrapper>
        <ErrorHandler>
          <PhonexRouter />
        </ErrorHandler>
      </I18nWrapper>
    </ReduxProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
